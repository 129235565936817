<template>
	<b-row style="padding-bottom: 10px">
		<b-col xs="12" lg="6" class="align-self-start" :style="{ 'text-align': text_align_first_col }">
			<b-form-select
				v-model="count"
				style="width: 150px; font-size: 12.5px; padding: 2px 17.5px 2px 10px; height: 30.75px"
				size="lg"
			>
				<b-form-select-option v-for="(countItem, index) of countOptions" :key="index" :value="countItem">
					{{ countItem.text }}
				</b-form-select-option>
			</b-form-select>

			<b-form-select
				v-model="period"
				style="
					margin-left: 10px;
					width: 150px;
					font-size: 12.5px;
					padding: 2px 17.5px 2px 10px;
					height: 30.75px;
				"
				size="lg"
				:options="filterPeriod(count)"
			></b-form-select>
		</b-col>

		<b-col
			xs="12"
			lg="6"
			class="align-self-end"
			:style="{ 'text-align': text_align_second_col, 'padding-top': padding_top_second_col }"
			style="display: flex; align-items: center; justify-content: end"
		>
			<functional-calendar v-model="calendarData" :configs="calendarConfigs" @dayClicked="dayClicked">
				<template #dateRangeInputs="props">
					<b-form-input
						size="lg"
						:value="props.startDate"
						type="text"
						placeholder="Date de début"
						style="
							height: 30.75px;
							border-top-left-radius: 0.2rem;
							border-bottom-left-radius: 0.2rem;
							font-size: 13px;
						"
					/>

					<b-form-input
						size="lg"
						:value="props.endDate"
						type="text"
						placeholder="Date de fin"
						style="
							height: 30.75px;
							border-top-right-radius: 0.2rem;
							border-bottom-right-radius: 0.2rem;
							font-size: 13px;
						"
					/>
				</template>
			</functional-calendar>

			<b-button
				squared
				variant="primary"
				class="refresh-btn"
				style="padding: 6.75px 7.5px; display: flex; justify-content: center; align-items: center"
				@click="refreshAllCharts"
			>
				<b-icon icon="arrow-repeat" style="width: 12.5px; height: 12.5px"></b-icon>
			</b-button>

			<!--
			<multiselect
				v-model="selected"
				style="width: 235px; margin-left: 10px; display: inline-block"
				:options="options"
				:multiple="true"
				:close-on-select="false"
				:clear-on-select="false"
				:preserve-search="true"
				:preselect-first="true"
				placeholder="Vos graphique(s) sélectioné(s)"
				label="title"
				track-by="key"
				deselect-label=""
				select-label=""
				selected-label=""
			>
				<template slot="selection" slot-scope="{ values, isOpen }">
					<span v-if="values.length &amp;&amp; !isOpen" class="multiselect__single">
						{{ values.length }} graphique(s) sélectioné(s)
					</span>
				</template>

				<template slot="option" slot-scope="props">
					<div class="option__desc">
						<span class="option__title">{{ props.option["title"] }}</span>
					</div>
				</template>
			</multiselect>
			-->
		</b-col>

		<b-col v-if="info_arrondies" style="padding: 10px 0px">
			<p class="information_date">{{ info_arrondies }}</p>
		</b-col>
	</b-row>
</template>

<script>
	import { storage } from "sinao-corejs";
	import { FunctionalCalendar } from "vue-functional-calendar";

	import dayjs from "dayjs";
	import "dayjs/locale/fr";
	import quarterOfYear from "dayjs/plugin/quarterOfYear";
	import customParseFormat from "dayjs/plugin/customParseFormat";

	dayjs.locale("fr");
	dayjs.extend(customParseFormat);
	dayjs.extend(quarterOfYear);

	window.dayjs = dayjs;

	export default {
		name: "Filters",
		components: {
			FunctionalCalendar
		},
		data() {
			return {
				info_arrondies: null,
				selected: null,
				startDate: null,
				endDate: null,
				calendarData: {
					dateRange: {
						start: null,
						end: null
					}
				},
				calendarConfigs: {
					dateFormat: "dd/mm/yyyy",
					isModal: true,
					isDateRange: true,
					isMultiple: true,
					calendarsCount: 2,
					isAutoCloseable: true,
					isLayoutExpandable: true,
					isMultipleDatePicker: true,
					dayNames: ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"],
					monthNames: ["Jan", "Fev", "Mar", "Avr", "Mai", "Juin", "Jul", "Aout", "Sept", "Oct", "Nov", "Dec"],
					shortMonthNames: [
						"Jan",
						"Fev",
						"Mar",
						"Avr",
						"Mai",
						"Juin",
						"Jul",
						"Aout",
						"Sept",
						"Oct",
						"Nov",
						"Dec"
					]
				},
				count: null,
				period: "yearly",
				periodOptions: [
					{ value: "daily", text: "Quotidien", count: null },
					{ value: "weekly", text: "Hebdomadaire", count: null },
					{ value: "monthly", text: "Mensuel", count: null },
					{ value: "quarterly", text: "Trimestriel", count: null },
					{ value: "yearly", text: "Annuel", count: null }
				],
				countOptions: [
					{
						id: 1,
						value: 7,
						text: "7 derniers jours",
						period: "daily",
						startDate: dayjs().subtract(7, "days"),
						endDate: dayjs()
					},
					{
						id: 2,
						value: 4,
						text: "4 dernières semaines",
						period: "weekly",
						startDate: dayjs().subtract(4, "weeks") /*.startOf("week")*/,
						endDate: dayjs()
					},
					{
						id: 3,
						value: 3,
						text: "3 derniers mois",
						period: "monthly",
						startDate: dayjs().subtract(3, "months").startOf("month"),
						endDate: dayjs()
					},
					{
						id: 4,
						value: 12,
						text: "12 derniers mois",
						period: "monthly",
						startDate: dayjs().subtract(12, "months").startOf("month"),
						endDate: dayjs()
					},
					{
						id: 5,
						value: dayjs().daysInMonth(),
						text: "Cumul mensuel",
						period: "daily",
						startDate: dayjs().startOf("month"),
						endDate: dayjs().endOf("month")
					},
					{
						id: 6,
						value: 3,
						text: "Cumul trimestriel",
						period: "monthly",
						startDate: dayjs().quarter(dayjs().quarter()).startOf("quarter"),
						endDate: dayjs().quarter(dayjs().quarter()).endOf("quarter")
					},
					{
						id: 7,
						value: 12,
						text: "Cumul annuel",
						period: "monthly",
						startDate: dayjs().startOf("year"),
						endDate: dayjs().endOf("year")
					},
					/*
					{
						id: 8,
						value: 0,
						text: "Tout",
						period: "monthly",
						startDate: null,
						endDate: null
					},
					*/
					{
						id: 9,
						value: null,
						text: "Personnalisé",
						period: "monthly"
					}
				],
				options: [],
				window_width: window.innerWidth
			};
		},
		computed: {
			monitor() {
				const { count, period, calendarData } = this.$data;

				return { count, period, calendarData };
			},
			text_align_second_col() {
				return this.window_width >= 992 ? "right" : "center";
			},
			text_align_first_col() {
				return this.window_width >= 992 ? "left" : "center";
			},
			padding_top_second_col() {
				return this.window_width >= 992 ? 0 : "10px";
			}
		},
		watch: {
			count: {
				handler() {
					if (this.count?.id) {
						const count = this.countOptions.find(count => count.id === this.count.id);

						if (count) {
							const { period, startDate, endDate } = count;

							this.period = period;

							if (startDate && endDate) {
								this.calendarData.dateRange.start = dayjs(startDate).format("D/M/YYYY");
								this.calendarData.dateRange.end = dayjs(endDate).format("D/M/YYYY");
							}
						}

						// this.props.startDate = "";
						// this.props.endDate = "";
					}
				},
				immediate: true,
				deep: true
			},
			monitor: {
				handler() {
					const { calendarData } = this.$data;
					const formattedStartAt = dayjs(calendarData.dateRange.start, "D/M/YYYY").format("YYYY-MM-DD");
					const formattedEndAt = dayjs(calendarData.dateRange.end, "D/M/YYYY").format("YYYY-MM-DD");

					this.info_arrondies = null;

					if (formattedStartAt !== "Invalid Date" && formattedEndAt !== "Invalid Date") {
						this.$nextTick(() => {
							let periodObj = this.periodOptions.find(p => p.value === this.period);

							if (!periodObj) {
								periodObj = this.periodOptions[0];
								this.period = this.periodOptions[0].value;
							}

							if (this.count?.value === null || (periodObj && periodObj?.count === null)) {
								const date1 = new Date(dayjs(formattedStartAt));
								const date2 = new Date(dayjs(formattedEndAt));
								const first_day = new Date(date1.getFullYear(), date1.getMonth(), 1).getDate();
								const last_day = new Date(date2.getFullYear(), date2.getMonth() + 1, 0).getDate();
								let difference = null;

								switch (this.period) {
									case "daily":
										difference =
											Math.round(dayjs(formattedEndAt).diff(formattedStartAt, "day", true)) + 1;
										break;

									case "weekly":
										difference = Math.ceil(
											dayjs(formattedEndAt).diff(formattedStartAt, "week", true)
										);
										break;

									case "monthly":
										difference = this.getMonthCrossed(date1, date2);

										if (date1.getDate() !== first_day || date2.getDate() !== last_day) {
											this.info_arrondies =
												"Les dates sélectionnées ont été arrondies pour qu'elles correspondent à cette unité. (01/" +
												formattedStartAt.substring(5, 7) +
												"/" +
												formattedStartAt.substring(0, 4) +
												" au 31/" +
												formattedEndAt.substring(5, 7) +
												"/" +
												formattedEndAt.substring(0, 4) +
												")";
										}
										break;

									case "yearly":
										difference = this.getYearCrossed(date1, date2);

										if (
											date1.getDate() !== first_day ||
											date2.getDate() !== last_day ||
											date1.getMonth() !== 1 ||
											date2.getMonth() !== 12
										) {
											this.info_arrondies =
												"Les dates sélectionnées ont été arrondies pour qu'elles correspondent à cette unité. (01/01/" +
												formattedStartAt.substring(0, 4) +
												" au 31/12/" +
												formattedEndAt.substring(0, 4) +
												")";
										}
										break;
								}

								if (difference === 0) {
									difference = 1;
								}

								window.postMessage({
									action: "update-all-charts",
									data: {
										count_id: null,
										count: difference,
										period: this.period,
										end_at: formattedEndAt,
										use_cache: false
									}
								});
							} else {
								window.postMessage({
									action: "update-all-charts",
									data: {
										count_id: this.count.id,
										count: periodObj?.count,
										period: this.period,
										end_at: formattedEndAt,
										use_cache: true
									}
								});
							}
						});
					}

					if (this.count?.value !== null) {
						storage.set(
							`charts_filters`,
							{
								count_id: this.count?.id,
								period: this.period
							},
							3600 * 24 * 365,
							"any"
						);
					}
				},
				deep: true
			},
			selected() {
				const elements = document.getElementsByTagName("vue-charts");

				if (elements && elements.length > 0) {
					for (const element of elements) {
						const key = element.getAttribute("name");

						if (key) {
							const selection = this.selected.find(selection => selection.key === key);
							const display = selection ? "block" : "none";

							element.style.display = display;
							if (element.parentElement) {
								element.parentElement.style.display = display;
							}
						}
					}
				}
			}
		},
		async created() {
			let { data } = (await storage.get("charts_filters", "any")) ?? {};
			let { count_id, period } = data ?? {};

			if (count_id && period) {
				this.period = period;
				this.count = this.countOptions.find(count => count.id === count_id);
			} else {
				this.count = this.countOptions.find(count => count.id === 4);
			}
		},
		mounted() {
			const elements = document.getElementsByTagName("vue-charts");

			if (elements && elements.length > 0) {
				for (const element of elements) {
					const key = element.getAttribute("name");
					const request_json = element.getAttribute("request_json");
					const title = JSON.parse(request_json)?.title;

					if (key) {
						this.options.push({
							key,
							title,
							element
						});
					}
				}

				this.selected = [...this.options];
			}
			window.addEventListener("resize", () => {
				this.window_width = window.innerWidth;
			});
		},
		methods: {
			refreshAllCharts() {
				window.postMessage({
					action: "update-all-charts",
					data: {
						force_refresh: true
					}
				});
			},
			dayClicked() {
				this.count = this.countOptions.find(count => count.value === null);
			},
			filterPeriod(countOpt) {
				let periodOpt = [];

				switch (countOpt?.id) {
					case 1: // 7 derniers jours
						periodOpt = [{ value: "daily", text: "Quotidien", count: 7 }]; // Math.round( (new Date("dd") - new Date("07-07-2022")) / (1000 * 3600 * 24) );
						break;
					case 2: // 4 dernières semaines
						periodOpt = [
							{
								value: "daily",
								text: "Quotidien",
								count: Math.round(
									dayjs(this.countOptions[1].endDate, "D/M/YYYY").diff(
										dayjs(this.countOptions[1].startDate, "D/M/YYYY").format("YYYY-MM-DD"),
										"day",
										true
									)
								)
							},
							{ value: "weekly", text: "Hebdomadaire", count: 4 }
						];
						break;
					case 3: // 3 derniers moisx
						periodOpt = [
							{
								value: "daily",
								text: "Quotidien",
								count: Math.round(
									dayjs(this.countOptions[2].endDate, "D/M/YYYY").diff(
										dayjs(this.countOptions[2].startDate, "D/M/YYYY").format("YYYY-MM-DD"),
										"day",
										true
									)
								)
							},
							{ value: "weekly", text: "Hebdomadaire", count: 12 },
							{ value: "monthly", text: "Mensuel", count: 3 }
						];
						break;
					case 4: // 12 derniers mois
						periodOpt = [
							{ value: "weekly", text: "Hebdomadaire", count: 48 },
							{ value: "monthly", text: "Mensuel", count: 12 },
							{ value: "quarterly", text: "Trimestriel", count: 4 }
						];
						break;
					case 5: // Cumul mensuel
						periodOpt = [
							{
								value: "daily",
								text: "Quotidien",
								count: Math.round(
									dayjs(this.countOptions[4].endDate, "D/M/YYYY").diff(
										dayjs(this.countOptions[4].startDate, "D/M/YYYY").format("YYYY-MM-DD"),
										"day",
										true
									)
								)
							}
						];
						break;
					case 6: // Cumul trimestriel
						periodOpt = [
							{
								value: "daily",
								text: "Quotidien",
								count: Math.round(
									dayjs(this.countOptions[5].endDate, "D/M/YYYY").diff(
										dayjs(this.countOptions[5].startDate, "D/M/YYYY").format("YYYY-MM-DD"),
										"day",
										true
									)
								)
							},
							{
								value: "weekly",
								text: "Hebdomadaire",
								count: Math.round(
									dayjs(this.countOptions[5].endDate, "D/M/YYYY").diff(
										dayjs(this.countOptions[5].startDate, "D/M/YYYY").format("YYYY-MM-DD"),
										"week",
										true
									)
								)
							}
						];
						break;
					case 7: // Cumul annuel
						periodOpt = [
							{
								value: "weekly",
								text: "Hebdomadaire",
								count: Math.round(
									dayjs(this.countOptions[6].endDate, "D/M/YYYY").diff(
										dayjs(this.countOptions[6].startDate, "D/M/YYYY").format("YYYY-MM-DD"),
										"week",
										true
									)
								)
							},
							{
								value: "monthly",
								text: "Mensuel",
								count: Math.round(
									dayjs(this.countOptions[6].endDate, "D/M/YYYY").diff(
										dayjs(this.countOptions[6].startDate, "D/M/YYYY").format("YYYY-MM-DD"),
										"month",
										true
									)
								)
							},
							{
								value: "quarterly",
								text: "Trimestriel",
								count: Math.round(
									dayjs(this.countOptions[6].endDate, "D/M/YYYY").diff(
										dayjs(this.countOptions[6].startDate, "D/M/YYYY").format("YYYY-MM-DD"),
										"quarter",
										true
									)
								)
							}
						];
						break;
					default:
						periodOpt = [
							{ value: "daily", text: "Quotidien", count: null },
							{ value: "weekly", text: "Hebdomadaire", count: null },
							{ value: "monthly", text: "Mensuel", count: null },
							// { value: "quarterly", text: "Trimestriel", count: null },
							{ value: "yearly", text: "Annuel", count: null }
						];
				}

				this.periodOptions = periodOpt;

				return periodOpt;
			},
			getMonthCrossedBetween2Dates(date1, date2) {
				let mounthActualy = date1.getMonth();

				while (mounthActualy <= date2.getMonth()) {
					mounthActualy++;
				}

				return Math.abs(mounthActualy - date1.getMonth());
			},
			getMonthCrossed(date1, date2) {
				let count = 0;
				let yearActualy = date1.getFullYear();

				if (this.getYearCrossed(date1, date2) === 1) {
					count += this.getMonthCrossedBetween2Dates(date1, date2);
				} else {
					while (yearActualy < date2.getFullYear()) {
						count += this.getMonthCrossedBetween2Dates(date1, new Date(yearActualy.toString() + "/12/31"));
						yearActualy++;
						count += this.getMonthCrossed(new Date(yearActualy.toString() + "/01/01"), date2);
					}
				}

				return Math.abs(count);
			},
			getYearCrossed(date1, date2) {
				let yearActualy = date1.getFullYear();

				while (yearActualy <= date2.getFullYear()) {
					yearActualy++;
				}

				return Math.abs(yearActualy - date1.getFullYear());
			}

			/* --- TEST --- */
			// console.log( getMonthCrossed(new Date('2022/01/01'), new Date('2022/07/11')) );
			// console.log( getMonthCrossed(new Date('2022/01/31'), new Date('2022/07/11')) );
			// console.log( getMonthCrossed(new Date('2021/12/17'), new Date('2022/07/11')) );
			// console.log( getMonthCrossed(new Date('2021/05/17'), new Date('2022/07/11')) );
		}
	};
</script>

<style lang="scss">
	.refresh-btn {
		margin-bottom: 1px;
		margin-left: 6px;
		font-family: rubik;
		background-color: #003b51;
		border-color: #003b51;
	}

	.vfc-popover-container {
		/*width: 250px;*/
		display: inline-block;
	}

	.vfc-multiple-input {
		display: flex;
	}

	.vfc-multiple-input input {
		width: 125px !important;
		/*font-size: 1.25rem !important; */
		border: 1px solid #ced4da !important;
		color: #495057 !important;
	}
</style>

<style>
	.information_date {
		color: #999;
		font-size: 14px;
		font-weight: 400;
		text-align: end;
		padding-right: 15px;
		padding-left: 15px;
		margin: 0;
	}
	fieldset[disabled] .multiselect {
		pointer-events: none;
	}
	.multiselect__spinner {
		position: absolute;
		right: 1px;
		top: 1px;
		width: 48px;
		height: 35px;
		background: #fff;
		display: block;
	}
	.multiselect__spinner:before,
	.multiselect__spinner:after {
		position: absolute;
		content: "";
		top: 50%;
		left: 50%;
		margin: -8px 0 0 -8px;
		width: 16px;
		height: 16px;
		border-radius: 100%;
		border-color: #41b883 transparent transparent;
		border-style: solid;
		border-width: 2px;
		box-shadow: 0 0 0 1px transparent;
	}
	.multiselect__spinner:before {
		animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
		animation-iteration-count: infinite;
	}
	.multiselect__spinner:after {
		animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
		animation-iteration-count: infinite;
	}
	.multiselect__loading-enter-active,
	.multiselect__loading-leave-active {
		transition: opacity 0.4s ease-in-out;
		opacity: 1;
	}
	.multiselect__loading-enter,
	.multiselect__loading-leave-active {
		opacity: 0;
	}
	.multiselect,
	.multiselect__input,
	.multiselect__single {
		font-family: inherit;
		font-size: 14px;
		touch-action: manipulation;
	}
	.multiselect {
		box-sizing: content-box;
		display: block;
		position: relative;
		width: 100%;
		min-height: 40px;
		text-align: left;
		color: #35495e;
	}
	.multiselect * {
		box-sizing: border-box;
	}
	.multiselect:focus {
		outline: none;
	}
	.multiselect--disabled {
		pointer-events: none;
		opacity: 0.6;
	}
	.multiselect--active {
		z-index: 50;
	}
	.multiselect--active:not(.multiselect--above) .multiselect__current,
	.multiselect--active:not(.multiselect--above) .multiselect__input,
	.multiselect--active:not(.multiselect--above) .multiselect__tags {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
	.multiselect--active .multiselect__select {
		transform: rotateZ(180deg);
	}
	.multiselect--above.multiselect--active .multiselect__current,
	.multiselect--above.multiselect--active .multiselect__input,
	.multiselect--above.multiselect--active .multiselect__tags {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
	.multiselect__input,
	.multiselect__single {
		position: relative;
		display: inline-block;
		min-height: 20px;
		line-height: 20px;
		border: none;
		border-radius: 5px;
		background: #fff;
		padding: 1px 0 0 5px;
		width: calc(100%);
		transition: border 0.1s ease;
		box-sizing: border-box;
		margin-bottom: 8px;
		vertical-align: top;
	}
	.multiselect__tag ~ .multiselect__input,
	.multiselect__tag ~ .multiselect__single {
		width: auto;
	}
	.multiselect__input:hover,
	.multiselect__single:hover {
		border-color: #cfcfcf;
	}
	.multiselect__input:focus,
	.multiselect__single:focus {
		border-color: #a8a8a8;
		outline: none;
	}
	.multiselect__single {
		padding-left: 6px;
		margin-bottom: 8px;
		/* new 
		font-size: 1.25rem;
		*/
	}
	.multiselect__tags-wrap {
		display: inline;
	}
	.multiselect__tags {
		min-height: 40px;
		display: block;
		padding: 8px 40px 0 8px;
		border-radius: 5px;
		border: 1px solid #e8e8e8;
		background: #fff;
		/* new 
		display: inline-block;
		width: 100%;
		padding: 0.375rem 1.75rem 0.375rem 0.75rem;
		font-weight: 400;
		line-height: 1.5;
		color: #495057;
		border: 1px solid #ced4da;
		border-radius: 0.25rem;
		-webkit-appearance: none;
		-moz-appearance: none;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		padding-left: 1rem;
		font-size: 1.25rem;
		*/
	}
	.multiselect__tag {
		position: relative;
		display: inline-block;
		padding: 4px 26px 4px 10px;
		border-radius: 5px;
		margin-right: 10px;
		color: #fff;
		line-height: 1;
		background: #41b883;
		margin-bottom: 5px;
		white-space: nowrap;
		overflow: hidden;
		max-width: 100%;
		text-overflow: ellipsis;
	}
	.multiselect__tag-icon {
		cursor: pointer;
		margin-left: 7px;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		font-weight: 700;
		font-style: initial;
		width: 22px;
		text-align: center;
		line-height: 22px;
		transition: all 0.2s ease;
		border-radius: 5px;
	}
	.multiselect__tag-icon:after {
		content: "×";
		color: #266d4d;
		font-size: 14px;
	}
	.multiselect__tag-icon:focus,
	.multiselect__tag-icon:hover {
		background: #369a6e;
	}
	.multiselect__tag-icon:focus:after,
	.multiselect__tag-icon:hover:after {
		color: white;
	}
	.multiselect__current {
		line-height: 16px;
		min-height: 40px;
		box-sizing: border-box;
		display: block;
		overflow: hidden;
		padding: 8px 12px 0;
		padding-right: 30px;
		white-space: nowrap;
		margin: 0;
		text-decoration: none;
		border-radius: 5px;
		border: 1px solid #e8e8e8;
		cursor: pointer;
	}
	.multiselect__select {
		line-height: 16px;
		display: block;
		position: absolute;
		box-sizing: border-box;
		width: 40px;
		height: 38px;
		right: 1px;
		top: 1px;
		padding: 4px 8px;
		margin: 0;
		text-decoration: none;
		text-align: center;
		cursor: pointer;
		transition: transform 0.2s ease;
	}
	.multiselect__select:before {
		position: relative;
		right: 0;
		top: 65%;
		color: #999;
		margin-top: 4px;
		border-style: solid;
		border-width: 5px 5px 0 5px;
		border-color: #999999 transparent transparent transparent;
		content: "";
	}
	.multiselect__placeholder {
		color: #adadad;
		display: inline-block;
		margin-bottom: 10px;
		padding-top: 2px;
	}
	.multiselect--active .multiselect__placeholder {
		display: none;
	}
	.multiselect__content-wrapper {
		position: absolute;
		display: block;
		background: #fff;
		width: 100%;
		max-height: 240px;
		overflow: auto;
		border: 1px solid #e8e8e8;
		border-top: none;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		z-index: 50;
		-webkit-overflow-scrolling: touch;
	}
	.multiselect__content {
		list-style: none;
		display: inline-block;
		padding: 0;
		margin: 0;
		min-width: 100%;
		vertical-align: top;
	}
	.multiselect--above .multiselect__content-wrapper {
		bottom: 100%;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		border-bottom: none;
		border-top: 1px solid #e8e8e8;
	}
	.multiselect__content::webkit-scrollbar {
		display: none;
	}
	.multiselect__element {
		display: block;
	}
	.multiselect__option {
		display: block;
		padding: 12px;
		min-height: 40px;
		line-height: 16px;
		text-decoration: none;
		text-transform: none;
		vertical-align: middle;
		position: relative;
		cursor: pointer;
		white-space: nowrap;
	}
	.multiselect__option:after {
		top: 0;
		right: 0;
		position: absolute;
		line-height: 40px;
		padding-right: 12px;
		padding-left: 20px;
	}
	.multiselect__option--highlight {
		background: #41b883;
		outline: none;
		color: white;
	}
	.multiselect__option--highlight:after {
		content: attr(data-select);
		background: #41b883;
		color: white;
	}
	.multiselect__option--selected {
		background: #f3f3f3;
		color: #35495e;
		font-weight: bold;
	}
	.multiselect__option--selected:after {
		content: attr(data-selected);
		color: silver;
	}
	.multiselect__option--selected.multiselect__option--highlight {
		background: #ff6a6a;
		color: #fff;
	}
	.multiselect__option--selected.multiselect__option--highlight:after {
		background: #ff6a6a;
		content: attr(data-deselect);
		color: #fff;
	}
	.multiselect--disabled {
		background: #ededed;
		pointer-events: none;
	}
	.multiselect--disabled .multiselect__current,
	.multiselect--disabled .multiselect__select {
		background: #ededed;
		color: #a6a6a6;
	}
	.multiselect__option--disabled {
		background: #ededed;
		color: #a6a6a6;
		cursor: text;
		pointer-events: none;
	}
	.multiselect__option--disabled.multiselect__option--highlight {
		background: #dedede !important;
	}
	.multiselect-enter-active,
	.multiselect-leave-active {
		transition: all 0.15s ease;
	}
	.multiselect-enter,
	.multiselect-leave-active {
		opacity: 0;
	}
	.multiselect__strong {
		margin-bottom: 10px;
		display: inline-block;
	}
	*[dir="rtl"] .multiselect {
		text-align: right;
	}
	*[dir="rtl"] .multiselect__select {
		right: auto;
		left: 1px;
	}
	*[dir="rtl"] .multiselect__tags {
		padding: 8px 8px 0px 40px;
	}
	*[dir="rtl"] .multiselect__content {
		text-align: right;
	}
	*[dir="rtl"] .multiselect__option:after {
		right: auto;
		left: 0;
	}
	*[dir="rtl"] .multiselect__clear {
		right: auto;
		left: 12px;
	}
	*[dir="rtl"] .multiselect__spinner {
		right: auto;
		left: 1px;
	}
	@keyframes spinning {
		from {
			transform: rotate(0);
		}
		to {
			transform: rotate(2turn);
		}
	}
</style>

<style>
	.multiselect__tags {
		min-height: 30.75px;
		display: block;
		border-radius: 0.2rem;
		border: 1px solid #ced4da !important;
		background: #fff;
		padding: 0px 40px 0 8px;
	}

	.multiselect__single {
		margin: 0;
		padding: 0;
		padding-top: 0px;
	}

	.multiselect__input,
	.multiselect__single {
		line-height: 29px;
	}
</style>
