<template>
	<div class="chart">
		<b-card style="padding: 20px">
			<b-row>
				<!-- Title -->
				<b-col cols="12" class="mb-2">
					<div class="flex-fill fs-16px" style="font-weight: 600 !important">
						{{ name }}
					</div>
				</b-col>
				<!-- Big total -->
				<b-col cols="12" class="mb-4">
					{{ sumOfEverything }}
				</b-col>
			</b-row>

			<!-- Loading -->
			<span v-if="loading" style="text-align: center">
				<b-spinner small variant="primary"></b-spinner> Chargement...
			</span>

			<!-- No data -->
			<span v-else-if="series && series.length === 0" style="text-align: center">
				Aucune donnée à afficher.
			</span>

			<!-- Chart -->
			<b-row v-else style="padding: 0">
				<!-- Totals -->
				<b-col v-if="totalsPosition === 'top' && totals && totals.length > 1" cols="12">
					<b-row v-for="(total, index) in totals" :key="index" class="row mb-2">
						<b-col cols="8">{{ total.name | truncate(50) }}</b-col>
						<b-col
							id="full-amount"
							style="text-align: right"
							cols="4"
							:title="total.sum | convertNumberToString"
						>
							{{ total.sum | kFormatter }}{{ getCurrency() }}
						</b-col>
					</b-row>
				</b-col>

				<!-- Apexchart -->
				<b-col style="padding: 0" :cols="totalsPosition === 'right' && totals && totals.length > 1 ? 8 : 12">
					<Apexchart v-if="series && series.length > 0" :options="options" :series="series" />
				</b-col>

				<!-- Totals -->
				<b-col v-if="totalsPosition === 'right' && totals && totals.length > 1" cols="4">
					<b-row v-for="(total, index) in totals" :key="index" class="row mb-2">
						<b-col cols="8">{{ total.name | truncate(50) }}</b-col>
						<b-col
							id="full-amount"
							style="text-align: right"
							cols="4"
							:title="total.sum | convertNumberToString"
						>
							{{ total.sum | kFormatter }}{{ getCurrency() }}
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</b-card>
	</div>
</template>

<script>
	import dayjs from "dayjs";
	import "dayjs/locale/fr";
	import quarterOfYear from "dayjs/plugin/quarterOfYear";
	import weekOfYear from "dayjs/plugin/weekOfYear";
	import customParseFormat from "dayjs/plugin/customParseFormat";
	import { query, convertNumberToString, storage } from "sinao-corejs";

	const { VUE_APP_NUMBER_OF_VALUES_TO_SHOW, VUE_APP_HIDDEN_VALUES_KEY } = process.env;
	import apexchartsConfig from "../config";

	dayjs.locale("fr");
	dayjs.extend(customParseFormat);
	dayjs.extend(quarterOfYear);
	dayjs.extend(weekOfYear);

	const kFormatter = num => {
		const o = Intl.NumberFormat("fr-FR", {
			notation: "compact",
			compactDisplay: "short"
		});

		return o.format(num);
	};

	const days = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"];
	const months = ["Jan", "Fev", "Mar", "Avr", "Mai", "Jun", "Jui", "Aou", "Sep", "Oct", "Nov", "Dec"];
	const dictionary = {
		count_id: "Nombre total",
		count_paid_at: "Nombre payés",
		sum_amount: "Somme des montants"
	};

	export default {
		name: "Chart",
		filters: {
			kFormatter(value) {
				return kFormatter(value);
			},
			convertNumberToString(value) {
				return convertNumberToString(value);
			},
			truncate(str, n = 25) {
				return str && str.length > n ? str.substr(0, n - 1) + "..." : str;
			}
		},
		props: {
			appId: {
				type: String,
				default: null,
				required: false
			},
			id: {
				type: String,
				default: null,
				required: false
			},
			type: {
				type: String,
				default: null,
				required: false
			},
			name: {
				type: String,
				default: null,
				required: false
			},
			totalsPosition: {
				type: String,
				default: "right",
				required: false
			},
			currency: {
				type: String,
				default: "€",
				required: false
			}
		},
		data() {
			return {
				loading: true,
				series: null,
				labels: null,
				totals: null,
				count: null,
				period: null,
				endAt: null,
				sumOfEverything: null,
				formatToMoney: false,
				divideBy: 100,
				options: {
					yaxis: {
						tickAmount: 4,
						labels: {
							show: true,
							formatter: (value, opts) => {
								if (this.formatToMoney) {
									let amount = value;

									if (amount >= 1000 || amount <= -1000) {
										amount = kFormatter(amount);
									} else {
										amount = convertNumberToString(amount);
									}

									return amount + this.getCurrency();
								}

								return value;
							}
						}
					},
					tooltip: {
						shared: true,
						intersect: false,
						followCursor: true,
						onDatasetHover: {
							highlightDataSeries: true
						},
						x: {
							formatter: (value, opts) => {
								if (this.formatToMoney) {
									let amount = opts.w.globals.stackedSeriesTotals[opts.dataPointIndex];

									if (amount >= 1000 || amount <= -1000) {
										amount = kFormatter(amount);
									} else {
										amount = convertNumberToString(amount);
									}

									return `${value} (${amount})` + this.getCurrency();
								}

								return value;
							}
						},
						y: {
							title: {
								formatter: seriesName => {
									return (dictionary[seriesName] ?? seriesName) + " : ";
								}
							}
						}
					},
					plotOptions: {
						pie: {
							donut: {
								labels: {
									show: true,
									value: {
										show: true,
										formatter: (value, opts) => {
											if (this.formatToMoney) {
												const amount = value;

												return convertNumberToString(amount) + this.getCurrency();
											}

											return value;
										}
									}
								}
							}
						}
					}
				}
			};
		},
		mounted() {
			window.addEventListener("message", event => this.updateChart(event));
		},
		methods: {
			getCurrency() {
				try {
					return _CURRENCY_;
				} catch (error) {
					return this.currency;
				}
			},
			updateChart(event) {
				const { action, data } = event.data ?? {};

				// eslint-disable-next-line compat/compat
				let params = new URL(document.location).searchParams;
				let local = params.get("local");

				if (
					local === "true" ||
					(action && data && action === "update-all-charts" && Object.keys(data).length > 0)
				) {
					const { count, period, force_refresh, use_cache, end_at, count_id } = data ?? {};

					this.series = null;
					this.labels = null;
					this.totals = null;
					this.sumOfEverything = null;
					this.formatToMoney = false;

					if (force_refresh === true) {
						const localStorageName = `${this.id}_${this.type}_${this.count}_${this.period}`;
						storage.remove(localStorageName, this.appId);
					} else {
						this.count = count;
						this.period = period;
						this.endAt = end_at;
					}

					this.showChart({
						count: this.count,
						period: this.period,
						endAt: this.endAt,
						useCache: use_cache,
						countId: count_id
					});
				}
			},
			async showChart({ count, period, endAt, useCache, countId }) {
				try {
					const localStorageName = `${this.id}_${this.type}_${countId}_${this.count}_${this.period}`;

					let response = null;
					let cache = null;

					this.loading = true;

					// Don't use the cache when the dates are custom
					if (useCache === true) {
						cache = (await storage.get(localStorageName, this.appId))?.data;
					}

					if (cache && Object.keys(cache).length > 0) {
						response = cache;
					} else {
						response = await query({
							operationId: "app.statistics.charts.get",
							params: {
								appId: this.appId,
								type: this.id,
								count,
								period,
								start_at: endAt
							}
						});

						// Don't store in the cache when the count is custom
						if (useCache === true) {
							storage.set(localStorageName, response, 3600, this.appId);
						}
					}

					if (response) {
						const periods = response?.periods?.reverse();
						const groupByObjectName = response?.group_by_object_name;

						this.formatToMoney = !Object.entries(response.periods[0].data)[0][0].includes("count");

						if (this.formatToMoney === false) {
							this.divideBy = 1;
						}

						this.yaxis(periods, groupByObjectName, this.type);

						/*
						if (this.series) {
							this.calculation();
						}
						*/

						this.options = {
							...this.options,
							...{
								...Object.assign(this.options, apexchartsConfig.options),
								...Object.assign(this.options, apexchartsConfig[this.type]),
								labels: this.labels,
								chart: {
									type: this.type,
									stacked: this.type === "bar" ? true : false
								},
								xaxis: {
									tickAmount: 4,
									categories: this.xaxis(periods)
								}
							}
						};
					}
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false;
				}
			},
			xaxis(periods) {
				let xaxis = null;

				if (periods) {
					xaxis = periods.map(entry => {
						const { interval } = entry;
						const endAt = new Date(interval.end_at);
						// const endAt = new Date(interval.end_at.split("T")[0]);

						switch (this.period) {
							case "daily":
								return `${(endAt.getDate() < 10 ? "0" : "") + endAt.getDate()} ${days[endAt.getDay()]}`;

							case "weekly":
								return `S${dayjs(endAt).week()}`;

							case "monthly":
								return months[endAt.getMonth()];

							case "quarterly":
								return `Q${dayjs(endAt).quarter()}`;

							case "yearly":
								return endAt.getFullYear();

							default:
								return months[endAt.getMonth()];
						}
					});
				}

				return xaxis;
			},
			yaxis(periods, groupByObjectName, type) {
				const _series = [];
				const _labels = [];
				const _totals = [];
				let _sum = 0;

				const getKey = (sortedKeys, nbrOfResults, searchKey) => {
					const index = sortedKeys.findIndex(([key]) => key === searchKey);

					if (index > nbrOfResults) {
						return VUE_APP_HIDDEN_VALUES_KEY;
					}

					if (groupByObjectName && groupByObjectName[searchKey]) {
						return groupByObjectName[searchKey];
					} else {
						if (searchKey) {
							return `Non renseigné (id: ${searchKey})`;
						} else {
							return `Non renseigné`;
						}
					}
				};

				if (periods) {
					let sortedKeys = null;
					let groupAllValuesByKey = {};

					periods.map(entry => {
						const { data } = entry;

						// Sum of all keys
						Object.values(data).forEach(item => {
							Object.keys(item).map(key => {
								if (!groupAllValuesByKey[key]) {
									groupAllValuesByKey[key] = 0;
								}

								groupAllValuesByKey[key] += item[key];
							});
						});

						// Sort by values
						sortedKeys = Object.entries(groupAllValuesByKey).sort((a, b) => {
							const valueA = a[1];
							const valueB = b[1];

							if (valueA > valueB) {
								return -1;
							}

							if (valueA < valueB) {
								return 1;
							}

							return 0;
						});
					});

					if (type === "pie" || type === "donut") {
						sortedKeys.forEach(([groupKey, groupValue]) => {
							const _groupKey = getKey(sortedKeys, VUE_APP_NUMBER_OF_VALUES_TO_SHOW, groupKey);
							const _groupValue = groupValue / this.divideBy;

							const index = _labels.findIndex(label => label === _groupKey);

							if (index === -1) {
								_labels.push(_groupKey);
								_series.push(Math.abs(_groupValue));
							} else {
								_series[index] += Math.abs(_groupValue);
							}

							_sum += _groupValue;
						});
					} else {
						periods.map((entry, periodIndex) => {
							const { data } = entry;

							Object.keys(data).forEach((method, index2) => {
								let value = data[method];

								if (type === "bar") {
									// Stacked bar with group by
									Object.entries(value).forEach(([groupKey, groupValue]) => {
										const _groupKey = getKey(
											sortedKeys,
											VUE_APP_NUMBER_OF_VALUES_TO_SHOW * 2,
											groupKey
										);
										const _groupValue = groupValue / this.divideBy;

										// Search for the index serie using the _groupKey
										let serieByGroupIndex = _series.findIndex(serie => {
											return serie.name === _groupKey;
										});

										if (serieByGroupIndex === -1) {
											_series.push({
												name: _groupKey,
												data: Array(periods.length).fill(null)
											});

											_labels.push(_groupKey);

											serieByGroupIndex = _series.length - 1;

											_totals[serieByGroupIndex] = {
												name: _groupKey,
												sum: 0
											};
										}

										_series[serieByGroupIndex].data[periodIndex] += _groupValue;
										_totals[serieByGroupIndex].sum += _groupValue;
										_sum += _groupValue;
									});
								}

								if (type === "area") {
									if (!_series[index2]) {
										_series[index2] = {
											name: method,
											data: []
										};
									}

									_series[index2].data.push(value / this.divideBy);
									_sum += value / this.divideBy;
								}
							});
						});
					}
				}

				if (this.formatToMoney) {
					this.sumOfEverything = `${convertNumberToString(_sum)}` + this.getCurrency();
				} else {
					this.sumOfEverything = _sum;
				}
				// sort series
				if (type === "bar") {
					this.series = _series.sort((a, b) => {
						if (a.data !== undefined && b.data !== undefined) {
							const tot_a = a.data.reduce(
								(accumulator, currentValue) => accumulator + (currentValue ?? 0),
								0
							);
							const tot_b = b.data.reduce(
								(accumulator, currentValue) => accumulator + (currentValue ?? 0),
								0
							);

							if (tot_a > tot_b) {
								return -1;
							}

							if (tot_a < tot_b) {
								return 1;
							}

							return 0;
						} else {
							if (a > b) {
								return -1;
							}

							if (a < b) {
								return 1;
							}

							return 0;
						}
					});
				} else {
					this.series = _series;
				}
				this.labels = _labels;
				// sort totals
				this.totals = _totals.sort((a, b) => {
					if (a.sum > b.sum) {
						return -1;
					}

					if (a.sum < b.sum) {
						return 1;
					}

					return 0;
				});
			},
			calculation() {
				const array = this.series[0]?.data ?? this.series;
				let totalSum = 0;

				if (this.type === "bar" && this.totals && this.totals.length > 0) {
					for (const { sum } of this.totals) {
						totalSum += Math.abs(sum);
					}
				} else {
					if (array.length > 0) {
						totalSum = array.reduce(
							(previousValue, currentValue) => Math.abs(previousValue) + Math.abs(currentValue)
						);
					}
				}

				if (this.formatToMoney) {
					totalSum = `${convertNumberToString(totalSum)}` + this.getCurrency();
				}

				// this.sumOfEverything = totalSum;
			}
		}
	};
</script>

<style lang="scss">
	@import "../../node_modules/bootstrap/dist/css/bootstrap.css";
	@import "../../node_modules/bootstrap-vue/dist/bootstrap-vue.css";

	@import "../assets/apexcharts.css";
	@import "../assets/main.scss";
</style>
