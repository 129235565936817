const app = {
	namespaced: true,
	state: () => ({
		appId: null,
		periods: [
			{
				text: "Heure",
				value: "hourly"
			},
			{
				text: "Jour",
				value: "daily"
			},
			{
				text: "Semaine",
				value: "weekly"
			},
			{
				text: "Mois",
				value: "monthly"
			},
			{
				text: "Trimestre",
				value: "quarterly"
			},
			{
				text: "Année",
				value: "yearly"
			}
		]
	}),
	mutations: {
		appId(state, payload) {
			state.appId = payload;
		}
	}
};

export default app;
