import Vue from "vue";
import vueCustomElement from "vue-custom-element";
import "@ungap/custom-elements";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/main.scss";

import App from "./App.vue";
import { store } from "./store";

// Sentry
// import * as Sentry from "@sentry/browser";
// import { Integrations } from "@sentry/tracing";

if (process.env.NODE_ENV === "production") {
	// Sentry.init({
	// 	Vue,
	// 	dsn: "https://5bbc332cb9b4400aa86d740d478a9ccf@o326214.ingest.sentry.io/5619174",
	// 	autoSessionTracking: true,
	// 	logErrors: true,
	// 	tracesSampleRate: 1.0,
	// 	environment: process.env.NODE_ENV,
	// 	integrations: [
	// 		new Integrations.BrowserTracing()
	// 		/*new SentryRRWeb({
	// 			recordLog: true
	// 			collectFonts: true,
	// 			recordCanvas: true
	// 		})*/
	// 	],
	// 	tracingOptions: {
	// 		trackComponents: true
	// 	}
	// });
}

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(vueCustomElement);
Vue.use(VueApexCharts);

Vue.component("Apexchart", VueApexCharts);

App.BootstrapVue = BootstrapVue;
App.IconsPlugin = IconsPlugin;
App.store = store;

Vue.config.productionTip = false;

Vue.customElement("vue-charts", App, {
	shadow: true,
	beforeCreateVueInstance(root) {
		const rootNode = root.el.getRootNode();

		if (rootNode instanceof ShadowRoot) {
			root.shadowRoot = rootNode;
		} else {
			root.shadowRoot = document.head;
		}

		return root;
	}
});
