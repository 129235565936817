const form = {
	namespaced: true,
	state: () => ({
		objects: [
			{
				text: "Factures",
				value: "invoices"
			},
			{
				text: "Achats",
				value: "purchases"
			}
		],
		comparators: [
			{
				text: "=",
				value: "="
			},
			{
				text: "!=",
				value: "!="
			},
			{
				text: "<",
				value: "<="
			},
			{
				text: ">",
				value: ">="
			},
			{
				text: "contains",
				value: "contains"
			},
			{
				text: "does not contain",
				value: "does not contain"
			},
			{
				text: "begin with",
				value: "begin with"
			},
			{
				text: "does not begin with",
				value: "does not begin with"
			},
			{
				text: "end with",
				value: "end with"
			},
			{
				text: "does not end with",
				value: "does not end with"
			},
			{
				text: "is null",
				value: "is null",
				isValueRequired: false
			},
			{
				text: "is not null",
				value: "is not null",
				isValueRequired: false
			},
			{
				text: "is empty",
				value: "is empty",
				isValueRequired: false
			},
			{
				text: "is not empty",
				value: "is not empty",
				isValueRequired: false
			},
			{
				text: "is between",
				value: "is between"
			},
			{
				text: "is not between",
				value: "is not between"
			},
			{
				text: "is in list",
				value: "is in list"
			},
			{
				text: "is not in list",
				value: "is not in list"
			}
		],
		aggregations: [
			{ text: "Somme", value: "sum" },
			{ text: "Moyenne", value: "avg" },
			{ text: "Compte", value: "count" }
		],
		orders: [
			{ text: "Descendant", value: "DESC" },
			{ text: "Ascendant", value: "ASC" }
		],
		configs: {
			invoices: {
				properties: [
					{
						text: "Montant",
						value: "amount"
					},
					{
						text: "Reste de l'invoice",
						value: "remaining_to_invoice"
					},
					{
						text: "ID",
						value: "id"
					}
				],
				dates: [
					{
						text: "Facturé le",
						value: "billed_at"
					},
					{
						text: "Écrit le",
						value: "written_at"
					},
					{
						text: "Reçu le",
						value: "received_at"
					},
					{
						text: "Créé(e) le",
						value: "created_at"
					}
				],
				groupBy: [
					{
						text: "Facturé le",
						value: "billed_at"
					},
					{
						text: "Écrit le",
						value: "written_at"
					},
					{
						text: "Reçu le",
						value: "received_at"
					},
					{
						text: "Créé(e) le",
						value: "created_at"
					}
				],
				filters: [
					{
						text: "Facturé le",
						value: "billed_at"
					},
					{
						text: "Écrit le",
						value: "written_at"
					},
					{
						text: "Reçu le",
						value: "received_at"
					},
					{
						text: "Créé(e) le",
						value: "created_at"
					}
				],
				search: null
			},
			purchases: {}
		}
	})
};

export default form;
