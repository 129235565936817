import Vue from "vue";
import Vuex from "vuex";

import app from "./app";
import form from "./form";

Vue.use(Vuex);

const store = new Vuex.Store({
	modules: {
		app,
		form
	}
});

export { store };
