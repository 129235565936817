<template>
	<div>
		<Filters v-if="!name" v-bind="$props" />
		<Chart v-else v-bind="$props" />
	</div>
</template>

<script>
	import Chart from "./views/Chart";
	import Filters from "./views/Filters";

	export default {
		name: "App",
		components: {
			Chart,
			Filters
		},
		props: {
			appId: {
				type: String,
				default: null,
				required: false
			},
			id: {
				type: String,
				default: null,
				required: false
			},
			type: {
				type: String,
				default: null,
				required: false
			},
			name: {
				type: String,
				default: null,
				required: false
			},
			totalsPosition: {
				type: String,
				default: "right",
				required: false
			},
			currency: {
				type: String,
				default: "€",
				required: false
			}
		}
	};
</script>

<style lang="scss">
	@import "../node_modules/bootstrap/dist/css/bootstrap.css";
	@import "../node_modules/bootstrap-vue/dist/bootstrap-vue.css";
	// @import "./assets/css/main.css";
</style>
