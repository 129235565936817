export default {
	options: {
		colors: [
			"#78C9DE",
			"#C2CFE0",
			"#334D6E",
			"#137EC8",
			"#E3465E",
			"#EBEFF2",
			"#6D6FBD",
			"#211B13",
			"#F08500",
			"#ED6A88",
			"#369E3B"
		],
		xaxis: {
			tickAmount: 4,
			datetimeFormatter: {
				year: "yyyy",
				month: "MMM 'yy",
				day: "dd MMM",
				hour: "HH:mm"
			}
		},
		toolbar: {
			show: true,
			offsetX: 0,
			offsetY: 0,
			tools: {
				download: true,
				selection: false,
				zoom: false,
				zoomin: false,
				zoomout: false,
				pan: false,
				reset: false
			}
		},
		zoom: {
			enabled: false
		},
		legend: {
			show: false
		},
		dataLabels: {
			enabled: false
		}
	},
	line: {
		dataLabels: { enabled: true },
		stroke: {
			curve: "smooth",
			width: 3
		},
		grid: {
			borderColor: "#dae0ec",
			row: {
				colors: ["#dae0ec", "transparent"],
				opacity: 0.5
			}
		}
	},
	bar: {
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: "55%",
				borderRadius: 6,
				endingShape: "rounded"
			}
		},
		dataLabels: { enabled: false },
		stroke: {
			show: true,
			width: 2,
			colors: ["transparent"]
		},
		grid: { borderColor: "#dae0ec" },
		fill: { opacity: 1 }
	},
	area: {
		dataLabels: { enabled: false },
		stroke: {
			curve: "smooth",
			width: 3
		},
		grid: { borderColor: "#dae0ec" }
	},
	pie: {
		plotOptions: {
			pie: {
				expandOnClick: false,
				// customScale: 1,
				donut: {
					labels: {
						show: false
					}
				}
			}
		},
		dataLabels: {
			dropShadow: {
				enabled: false
			}
		}
	},
	mixed: {
		series: [
			{ name: "Income", type: "column", data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6] },
			{ name: "Cashflow", type: "column", data: [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5] },
			{ name: "Revenue", type: "line", data: [20, 29, 37, 36, 44, 45, 50, 58] }
		],
		options: {
			chart: { type: "line", stacked: false },
			dataLabels: { enabled: false },
			stroke: { width: [0, 0, 3] },
			title: {
				text: "XYZ - Stock Analysis (2012 - 2020)",
				align: "left",
				offsetX: 110
			},
			xaxis: { categories: [2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020] },
			yaxis: [
				{
					axisTicks: { show: true },
					axisBorder: { show: true, color: "#1f6bff" },
					labels: { style: { color: "#1f6bff" } },
					title: {
						text: "Income (thousand crores)",
						style: { color: "#1f6bff" }
					},
					tooltip: { enabled: true }
				},
				{
					seriesName: "Income",
					opposite: true,
					axisTicks: { show: true },
					axisBorder: { show: true, color: "#0cd096" },
					labels: { style: { color: "#0cd096" } },
					title: {
						text: "Operating Cashflow (thousand crores)",
						style: { color: "#0cd096" }
					}
				},
				{
					seriesName: "Revenue",
					opposite: true,
					axisTicks: { show: true },
					axisBorder: { show: true, color: "#FF9500" },
					labels: { style: { color: "#FF9500" } },
					title: {
						text: "Revenue (thousand crores)",
						style: { color: "#FF9500" }
					}
				}
			],
			legend: {
				horizontalAlign: "left",
				offsetX: 40
			}
		}
	}
};
